<template>
  <div
    class="program"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 列表 -->
    <div class="programlist">
      <!-- 上 -->
      <div style="display: flex; margin-bottom: 5px">
        <el-form :model="SerachInput" label-width="80px">
          <div style="display: flex; flex-wrap: wrap; align-items: center">
            <el-form-item label="创建时间">
              <el-date-picker
                v-model="overallForm.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="SerachInput.useStatus"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in opStatus"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="用户信息">
              <el-select
                v-model="SerachInput.userInfo"
                placeholder="请选择"
                clearable
                @change="userOne"
              >
                <el-option
                  v-for="item in opInfo"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="SerachInput.valueInfo"
                placeholder=""
                style="width: 150px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="IMEI">
              <el-input
                v-model="SerachInput.imei"
                placeholder="请输入订单号"
                style="width: 150px"
                clearable
              ></el-input>
            </el-form-item>
            <img
              src="../../assets/images/query.png"
              alt=""
              @click="imaClick"
              style="
                width: 49px;
                height: 29px;
                margin: 6px;
                margin-top: 5px;
                margin-left: 10px;
              "
            />
          </div>
        </el-form>
      </div>
      <!-- table -->
      <div class="tables">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            prop="pmid"
            label="救援服务注册ID"
            width="215px"
          ></el-table-column>
          <el-table-column
            prop="serviceStart"
            label="创建时间"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="imei"
            label="设备IMEI号"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="equipmentServiceEnd"
            label="设备服务时间"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="memberSparePhone"
            label="用户手机"
            width="130px"
          ></el-table-column>
          <el-table-column
            prop="memberRealName"
            label="真实姓名"
            width="130px"
          ></el-table-column>
          <el-table-column
            prop="memberIdcard"
            label="身份证号码"
            width="170px"
          ></el-table-column>
          <el-table-column prop="openRescue" label="开通远盟"></el-table-column>
          <el-table-column
            prop="address"
            label="注册地址"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="useStatusCn"
            label="状态"
            width="160px"
          ></el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <div
            style="
              margin-left: 25px;
              margin-bottom: 15px;
              font-size: 16px;
              color: #606266;
            "
          >
            救援服务ID总数:{{ totalCount }}
          </div>
        </div>
        <div class="bottom-right">
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next"
                :total="totalCount"
              >
              </el-pagination>
              <!-- <el-pagination layout="prev, pager, next" :total="50">
              </el-pagination> -->
            </div>
          </div>
          <div class="buttons">
            <el-button @click="exportList">数据导出</el-button>
            <!-- <el-button>数据分析</el-button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
export default {
  data() {
    return {
      SerachInput: {},
      overallForm: {
        time: [],
      },
      opStatus: [
        {
          value: 2,
          name: "已绑定",
        },
        {
          value: 1,
          name: "未绑定",
        },
        {
          value: 3,
          name: "作废",
        },
      ],
      opInfo: [
        {
          value: 1,
          name: "手机",
        },
        {
          value: 2,
          name: "身份证号",
        },
        {
          value: 3,
          name: "真实姓名",
        },
      ],
      clientHeight: document.body.clientHeight,
      //分页
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      connected: [],
      tableData: [],
      formInline: {
        openRescue: "",
        useStatus: "",
      },
      lineId: null,
      radioLei: [],
    };
  },
  created() {
    this.getList();
    // this.yes();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    userOne() {
      this.SerachInput.valueInfo = "";
    },
    imaClick() {
      this.getList();
    },
    yes() {
      api.getSelectProduct({ dictCode: "isno" }).then((res) => {
        //console.log("下拉框：", res.data);
        if (res.data.code == 200) {
          this.connected = res.data.result;
        }
      });
    },
    getList() {
      apiShout
        .getque({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          useStatus: this.SerachInput.useStatus,
          startTime:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          endTime:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          imei: this.SerachInput.imei,
          type: this.SerachInput.userInfo,
          value: this.SerachInput.valueInfo,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //导出
    exportList() {
      let that = this;
      that
        .$http({
          url: "/too/center/ymuser/exportUserList",
          params: {
            useStatus: this.SerachInput.useStatus,
            startTime:
              this.overallForm.time == null ? "" : this.overallForm.time[0],
            endTime:
              this.overallForm.time == null ? "" : this.overallForm.time[1],
            imei: this.SerachInput.imei,
            type: this.SerachInput.userInfo,
            value: this.SerachInput.valueInfo,
          },
          method: "get",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          // console.log(res,"查询")
          console.log("导出数据：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "救援信息查询" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    //分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.getList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.getList();
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  line-height: 33px;
  height: auto;
}
/deep/.el-date-editor .el-range-separator {
  height: auto;
}
/deep/.el-table th.el-table__cell > .cell {
  font-size: 14px;
  color: #909399;
}
/deep/.el-table td.el-table__cell div {
  color: #606266;
  font-size: 14px;
}
/deep/.el-table .el-table__cell {
  text-align: center;
}
/deep/.el-pager {
  margin-top: 5px;
}
.program {
  position: absolute;
  height: 100%;
  width: 100%;
  // 列表
  .programlist {
    margin-left: 25px;
    margin-right: 40px;
    // 上
    .protop {
      position: relative;
      margin-top: 25px;
      height: auto;
      margin-right: 40px;
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        color: #909399;
        font-size: 14px;
        font-weight: bold;
      }
      // input框
      .el-input__inner {
        border-radius: 0;
        border: 1px solid #e1e1e1;
      }
      // 知识分类
      .knowledge {
        position: absolute;
        top: 3px;
        .el-input {
          width: 190px;
          margin-right: 0px;
        }
      }
      // 播放次数
      .play {
        position: absolute;
        left: 300px;
        top: 3px;
        .el-input {
          width: 134px;
        }
      }
      // 关键词
      .antistop {
        position: absolute;
        left: 700px;
        top: 3px;
        display: flex;
        align-items: center;
        .spanguan {
        }
        .el-input {
          width: 230px;
        }
        .buttons {
          margin-left: 14px;
        }
      }
    }
    // table
    .tables {
      margin-top: 10px;
      .el-table::before {
        height: 0;
      }
      .el-table {
        td,
        th.is-leaf {
          border: none !important;
        }
        tr th {
          font-size: 19px;
          color: #a8a8a8;
          text-align: center;
        }
        tr td {
          text-align: center;
          font-size: 17px;
          color: #a8a8a8;
        }
      }
      .buts {
        width: 36px;
        height: 27px;
      }
      .bu {
        margin-right: 20px;
        margin-left: 20px;
      }
    }
    /deep/ .el-table .el-table__cell {
      padding: 12px 0;
      min-width: 0;
      box-sizing: border-box;
      text-overflow: ellipsis;
      vertical-align: middle;
      position: relative;
      text-align: center;
    }
    /deep/ .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    /deep/ th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    /deep/ .el-table td,
    .el-table th.is-leaf {
      border-bottom: none;
    }
    /deep/ .el-table td,
    .el-table th.is-leaf {
      border-bottom: none !important;
    }
    // 总结
    .total {
      display: flex;
      justify-content: space-between;
      margin-right: 0px;
      margin-top: 30px;
      margin-left: 34px;
      p {
        font-size: 15px;
        display: inline-block;
        margin-right: 36px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
  }
}
/deep/.el-pager li {
  padding: 0 4px;
  font-size: 13px;
  min-width: 35.5px;
  height: 28px;
  line-height: 15px;
  box-sizing: border-box;
  text-align: center;
}
.bottom {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  margin-top: 10px;
}
.bottom-right {
  float: right;
  margin-right: 20px;
}
.bottom-left {
  position: relative;
  //   display: flex;
}
// 按钮
.buttons {
  display: flex;
  justify-content: flex-end;
  // margin-right: 110px;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
/deep/.el-table td,
.el-table th {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
/deep/.el-form-item__label {
  color: #909399;
}
</style>